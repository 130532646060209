import React from "react"
import { Link } from "react-router-dom"
import EgodcoinUtil from "./EgodcoinUtil"

/**
 * Component and utility class for Egodcoin Explorer.
 * 
 * @author Andres Jakobs <andresjakobs@web.de>
 * @copyright Andres Jakobs <andresjakobs@web.de>
 */
class EgodcoinExplorer {

  static NetworkSelector(props) {
    return (
      <div>
        <select defaultValue={props.network} onChange={(e) => {
          props.history.push('/network/' + e.target.value); props.history.go(0)
        }}>
          <option value="egodcoin-testnet">Egodcoin Testnet</option>
          <option value="cardano-mainnet">Cardano Mainnet</option>
        </select>
      </div>
    )
  }

  static ActiveStakePoolsTable(props) {
    return (
      <div>
        <table id="active-stake-pools" className="w3-table-all w3-hoverable">
          <thead><tr><th>ID</th><th>Hash ID</th><th>Index</th><th>Pledge</th><th>Active</th><th>Meta ID</th><th>Margin</th><th>Fixed cost</th><th>Registration TX</th></tr></thead>
          <tbody>
            {props.data && Array.from(props.data).map((o, i) =>
              <EgodcoinExplorer.ActiveStakePoolsRow obj={o} key={i} network={props.network} currency={props.currency} />
            )}
          </tbody>
        </table>
      </div>
    )
  }

  static ActiveStakePoolsRow(props) {
    return (
      <tr id={props.obj.id}>
        <td>{props.obj.id}</td>
        <td>{props.obj.hash_id}</td>
        <td>{props.obj.cert_index}</td>
        <td><EgodcoinUtil.CurrencyFormat value={props.obj.pledge} currency={props.currency} /></td>
        <td>{props.obj.active_epoch_no}</td>
        <td>{props.obj.meta_id}</td>
        <td><EgodcoinUtil.CurrencyFormat value={props.obj.margin} currency={props.currency} /></td>
        <td><EgodcoinUtil.CurrencyFormat value={props.obj.fixed_cost} currency={props.currency} /></td>
        <td>{props.obj.registered_tx_id}</td>
      </tr>
    )
  }

  static BlockDetails(props) {
    return (
      <div>
        <table id="block-{props.block.hash}" className="w3-table-all w3-hoverable">
          <thead><tr><th colSpan="2">{props.networkLabel} Block {props.block.block_no.toLocaleString()}</th></tr></thead>
          <tbody>
            <tr>
              <td>Epoch</td>
              <td>{props.block.epoch_no.toLocaleString()}</td></tr>
            <tr>
              <td>Slot</td>
              <td>{props.block.slot_no.toLocaleString()}</td></tr>
            <tr>
              <td>Block</td>
              <td>{props.block.block_no.toLocaleString()}</td></tr>
            <tr>
              <td>Hash</td>
              <td className='hash'>{EgodcoinUtil.toHexString(props.block.hash.data)}</td></tr>
            <tr>
              <td>Time</td>
              <td>{props.block.time.toLocaleString()}</td></tr>
            <tr>
              <td>Transactions</td>
              <td>{props.block.tx_count.toLocaleString()}</td></tr>
            <tr>
              <td>Size</td>
              <td>{props.block && props.block.size !== "undefined" && <EgodcoinUtil.SizeFormat value={props.block.size} />} B</td></tr>
            <tr>
              <td>Slot Leader</td>
              <td>{props.block.slot_leader_id}</td></tr>
            <tr>
              <td>VRF Key</td>
              <td className='hash'>{props.block.vrf_key}</td></tr>
            <tr>
              <td>Operator Cert</td>
              <td className='hash'>{EgodcoinUtil.toHexString(props.block.op_cert.data)}</td>
            </tr>
          </tbody>
        </table>
      </div>
    )
  }

  static BlockTx(props) {
    return (
      <div>
        <table id="block-{props.tx.id}" className="w3-table-all w3-hoverable">
          <thead>
            <tr><th colSpan="5">{props.networkLabel} Block {props.block.block_no.toLocaleString()} Transactions</th></tr>
            <tr>
              <th>ID</th>
              <th>Hash (raw)</th>
              <th>Index</th>
              <th>Sum</th>
              <th>Fee</th>
            </tr>
          </thead>
          <tbody>
            {Array.from(props.tx).map((o, i) => <EgodcoinExplorer.TxRow obj={o} key={i} network={props.network} currency={props.currency} />)}
          </tbody>
        </table>
      </div>
    )
  }

  static TxRow(props) {
    return (
      <tr>
        <td>{props.obj.id}</td>
        <td className='hash'><Link to={`/${props.network}/tx/${EgodcoinUtil.toHexString(props.obj.hash.data)}`} className="nav-link">{EgodcoinUtil.toHexString(props.obj.hash.data)}</Link></td>
        <td>{props.obj.block_index}</td>
        <td><EgodcoinUtil.CurrencyFormat value={props.obj.out_sum} currency={props.currency} /></td>
        <td>{props.obj.fee}</td>
      </tr>
    )
  }

  static TxDetails(props) {
    return (
      <div>
        <table id="tx-{props.tx.id}" className="w3-table-all w3-hoverable">
          <thead>
            <tr><th colSpan="2">{props.networkLabel} TX {EgodcoinUtil.toHexString(props.tx.hash.data)}</th></tr>
          </thead>
          <tbody>
            <tr>
              <td>Block / Index</td>
              <td><Link to={`/${props.network}/block/${props.tx.block_id}`} className="nav-link">{`${props.tx.block_no.toLocaleString()}`}</Link> / {props.tx.block_index.toLocaleString()}</td></tr>
            <tr>
              <td>Hash (raw)</td>
              <td className='hash'>{EgodcoinUtil.toHexString(props.tx.hash.data)}</td></tr>
            <tr>
              <td>Sum</td>
              <td>{props.tx && props.tx.out_sum !== "undefined" && <EgodcoinUtil.CurrencyFormat value={props.tx.out_sum} currency={props.currency} />}</td></tr>
            <tr>
              <td>Fee</td>
              <td>{props.tx && props.tx.fee !== "undefined" && <EgodcoinUtil.CurrencyFormat value={props.tx.fee} currency={props.currency} />}</td></tr>
            <tr>
              <td>Deposit</td>
              <td>{props.tx && props.tx.deposit !== "undefined" && <EgodcoinUtil.CurrencyFormat value={props.tx.deposit} currency={props.currency} />}</td></tr>
            <tr>
              <td>Invalid Before</td>
              <td>{props.tx.invalid_before}</td></tr>
            <tr>
              <td>Invalid Hereafter</td>
              <td>{props.tx.invalid_hereafter}</td></tr>
            <tr>
              <td>Size</td>
              <td>{props.tx && props.tx.size !== "undefined" && <EgodcoinUtil.SizeFormat value={props.tx.size} />} B</td></tr>
            <tr>
              <td>Valid Contract</td>
              <td>{props.tx.valid_contract}</td></tr>
            <tr>
              <td>Script Size Max.</td>
              <td>{props.tx && props.tx.script_size !== "undefined" && <EgodcoinUtil.SizeFormat value={props.tx.script_size} />} B</td></tr>
          </tbody>
        </table>
      </div>
    )
  }

  static TxioRow(props) {
    console.log("3 Props: " + JSON.stringify(props))
    return (
      <tr>
        <td>{props.obj.index}</td>
        <td>{props.obj.stake_address_id}</td>
        <td>{props.obj.index}</td>
        <td>{props.obj.has_script}</td>
        <td><EgodcoinUtil.CurrencyFormat value={props.obj.value} currency={props.currency} /></td>
      </tr>
    )
  }

  static Txout(props) {
    return (
      <div>
        <table id="block-{props.tx.id}" className="w3-table-all w3-hoverable">
          <thead>
            <tr><th colSpan="5">{props.networkLabel} Txout {"\\x" + EgodcoinUtil.toHexString(props.tx.hash.data)}</th></tr>
            <tr>
              <th>Index</th>
              <th>Stake ID</th>
              <th>Index</th>
              <th>Script</th>
              <th>Value</th>
            </tr>
          </thead>
          <tbody>
            {Array.from(props.txout).map((o, i) => <EgodcoinExplorer.TxioRow obj={o} key={i} network={props.network} currency={props.currency} />)}
          </tbody>
        </table>
      </div>)
  }

  static Txin(props) {
    return (
      <div>
        <table id="block-{props.tx.id}" className="w3-table-all w3-hoverable">
          <thead>
            <tr><th colSpan="5">{props.networkLabel} Txin {"\\x" + EgodcoinUtil.toHexString(props.tx.hash.data)}</th></tr>
            <tr>
              <th>Index</th>
              <th>Stake ID</th>
              <th>Index</th>
              <th>Script</th>
              <th>Value</th>
            </tr>
          </thead>
          <tbody>
            {Array.from(props.txin).map((o, i) => <EgodcoinExplorer.TxioRow obj={o} key={i} network={props.network} currency={props.currency} />)}
          </tbody>
        </table>
      </div>)
  }
}

export default EgodcoinExplorer
