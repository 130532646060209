import React, { Component } from "react";
import { NavLink } from "react-router-dom";

class Home extends Component {
  render() {
    return (
    <div>
    <div className="w3-center w3-row-padding w3-border">
    <section className="w3-third">
      <h2>Technology</h2>
      <p>Egodcoin uses Cardano proof-of-stake third-generation blockchain platform. Egodcoin is a 100% compatible to <a href="https://cardano.org/" target="_blank" rel="noreferrer">Cardano</a>.</p>
    </section>
    <section className="w3-third">
      <h2>Proof-of-Stake</h2>
      <p>Egodoin is partially decentralized. Become a <NavLink to="/proof-of-stake">stake pool operator or delegate your stake</NavLink>. More information very soon!</p>
    </section>
    <section className="w3-third">
      <h2>Native Tokens</h2>
      <p>Egodcoin supports user defined native tokens. <NavLink to="/native-tokens">Learn more</NavLink> how to introduce your own token on the Egodcoin platform.</p>
    </section>
    <section className="w3-third">
      <h2>Non-fungible Tokens</h2>
      <p>Egodcoin supports <NavLink to="/non-fungible-tokens">NFT (non-fungible token)</NavLink>. Visit <a href="https://egodcoin.art/" target="_blank" rel="noreferrer">Egodcoin Art</a> our platform NFT art store comming soon!</p>
    </section>
    <section className="w3-third">
      <h2>Smart Contracts</h2>
      <p>Egodcoin Cardano blockchain platform runs in Cardano Alonzo Era! <NavLink to="/smart-contracts">Learn how to create or use smart contracts</NavLink>.</p>
    </section>
    <section className="w3-third">
      <h2>News</h2>
      <p><NavLink to="/testnet">Testnet</NavLink> reset at June the 19th 2021. Epoch time 24h. 10.000.000.000 Egodcoin plus 1.000.000.000 athletic tokens available for test.</p>
    </section>
    <figure className="w3-center">
        <img src="img/logo192.png" alt="To the moon... and beyond." className="w3-image"></img>
        <figcaption>Powered by <a href="https://reactjs.org/" target="_blank" rel="noreferrer">React</a>&nbsp;<a href="https://www.w3.org/" target="_blank" rel="noreferrer">W3C</a></figcaption>
    </figure>
    </div>
    <br/>
    </div>
    );
  }
}
 
export default Home;
