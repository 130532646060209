import React, { Component } from "react";
 
class Contact extends Component {
  render() {
    return (
      <div className="w3-west w3-row-padding">
        <h2>Contact</h2>
        <p>mailto: buy [at] egodcoin.cash</p>
      </div>
    );
  }
}
 
export default Contact;
