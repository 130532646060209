import React, { Component } from "react";
 
class Blockchain extends Component {
  render() {
	return (
    <div className="w3-west w3-row-padding">
      <p>Egodcoin uses Cardano proof-of-stake third-generation blockchain platform. Egodcoin is a 100% 
        compatible to <a href="https://cardano.org/" target="_blank" rel="noreferrer">Cardano</a>.<br/>
      </p>
      <h2>All About Transactions?</h2>
        <p>Blockchains store transactions (TX) in distributed ledgers. Storing TX does not require more 
          than the nodes software itself to manage keys, addresses, build and submit TX or to check the 
          balance of a payment address.
        </p>
      <h2>Proof-of-Stake</h2>
        <p>Consense mechanism is Proof-of-Stake (PoS).<br/><br/>Technical experienced stake holders might 
          participate as stake pool owners and operate their own stake pool(s). Stake holders who want to 
          participte in a passive way only might delegate stake to stake pools only to get incentivised.
        </p>
      <h2>Custom Tokens</h2>
        <p>Cardano allows management and usage of custom tokens in a native way. No smart contract is 
          required.<br/><br/>Mint, send/receive or burn tokens.</p>
      <h2>NFT</h2>
        <p>Non-fungible tokens (NFT) represent unique identifiers stored on the blockchain. NFT is dezentralized 
          identity management for virtual or real assets. NFTs typically require offchain data, applications 
          or services to become a use case<br/><br/>.
        </p>
      <h2>Smart Contracts</h2>
        <p>A smart contract is executable code stored on the blockchain and validated by offchain systems.
          In order to initiate a transactions, smart contracts validated conditions defineded therein using
          onchain or offchain data.
        </p>
    </div>
    );
  }
}
 
export default Blockchain;
