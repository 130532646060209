import React from "react"
import { withRouter } from "react-router"
import EgodcoinUtil from "./util/EgodcoinUtil"
import EgodcoinExplorer from "./util/EgodcoinExplorer"
import EgodcoinEpochs from "./util/EgodcoinEpochs"
import EgodcoinBlocks from "./util/EgodcoinBlocks"

var CryptoJS = require("crypto-js/core")
CryptoJS.SHA256 = require("crypto-js/sha256")

class Network extends React.Component {

  constructor({match}, props) {
    super(props);
    const apiUri = global.gConfig.api_base_uri + '/' + match.params.network
    this.state = {
      apiUri: apiUri,
      apiDocsUri: global.gConfig.api_docs_uri,
		  network: match.params.network,
		  networkLabel: EgodcoinUtil.toNetworkLabel(match.params.network),
		  currency: EgodcoinUtil.toCurrency(match.params.network),
      stats: null,
      epochs: null,
      blocks: null,
      pools: null
	  }
    fetch(apiUri + '/stats').then(response => { return response.text() })
	  .then(data => {
		  var obj = JSON.parse(data)
		  this.state.stats = obj
      this.setState(this.state);
	  });
    fetch(apiUri + '/chain/latestEpochs').then(response => { return response.text() })
	  .then(data => {
		  var obj = JSON.parse(data)
		  this.state.epochs = obj
      this.setState(this.state);
	  });
    fetch(apiUri + '/chain/latestBlocks').then(response => { return response.text() })
	  .then(data => {
		  var obj = JSON.parse(data)
		  this.state.blocks = obj
      this.setState(this.state);
	  });
	  fetch(apiUri + '/pool/activeStakePools').then(response => { return response.text() })
	  .then(data => {
		  var obj = JSON.parse(data)
		  this.state.pools = obj
      this.setState(this.state);
	  });
  }

  render() {
    const apiDocsUri = this.state.apiDocsUri
    var stats = this.state.stats
    const network = this.state.network
    const networkLabel = this.state.networkLabel
    const currency = this.state.currency
    const epochs = this.state.epochs
    const blocks = this.state.blocks
    const pools = this.state.pools
    return (
      <div className="w3-center w3-row-padding">
      <EgodcoinExplorer.NetworkSelector network={network} history={this.props.history}/>  
      <section className="w3-half">
        <h2>{networkLabel} Stats</h2>
        <table className="w3-table-all w3-medium w3-hoverable">
        <thead><tr><th colSpan="2">Monetary stats</th></tr></thead>
        <tbody className="w3-small">
        <tr><td>Max supply</td><td>{ stats && stats.supplyMax !== "undefined" && <EgodcoinUtil.CurrencyFormat value={stats.supplyMax} currency={stats.currency} /> }</td></tr>
        <tr><td>Current supply</td><td>{ stats && stats.supplyCurrent !== "undefined" && <EgodcoinUtil.CurrencyFormat value={stats.supplyCurrent} currency={stats.currency} /> }</td></tr>
        <tr><td>Pool fee</td><td>{ stats && stats.poolFee !== "undefined" && <EgodcoinUtil.CurrencyFormat value={stats.poolFee} currency={stats.currency} /> }</td></tr>
        <tr><td>Min. pool costs</td><td>{ stats && stats.poolCosts !== "undefined" && <EgodcoinUtil.CurrencyFormat value={stats.poolCosts} currency={stats.currency} /> }</td></tr>
        <tr><td>Min. utx0</td><td>{ stats && stats.utxoMinValue !== "undefined" && <EgodcoinUtil.CurrencyFormat value={stats.utxoMinValue} currency={stats.currency} /> }</td></tr>
        <tr><td>Stake key deposit</td><td>{ stats && stats.keyDeposit !== "undefined" && <EgodcoinUtil.CurrencyFormat value={stats.keyDeposit} currency={stats.currency} /> }</td></tr>
        <tr><td>TX variable fee</td><td>{ stats && stats.txVariableFee !== "undefined" && <EgodcoinUtil.CurrencyFormat value={stats.txVariableFee} currency={stats.currency} /> }</td></tr>
        <tr><td>TX fix fee</td><td>{ stats && stats.txFixFee !== "undefined" && <EgodcoinUtil.CurrencyFormat value={stats.txFixFee} currency={stats.currency} /> }</td></tr>
        <tr><td>Update proposal fee</td><td>{ stats && stats.updateProposalFee !== "undefined" && <EgodcoinUtil.CurrencyFormat value={stats.updateProposalFee} currency={stats.currency} /> }</td></tr>
        <tr><td>Update proposal size max.</td><td>{ stats && stats.updateProposalMaxSize !== "undefined" && <EgodcoinUtil.SizeFormat value={stats.updateProposalMaxSize} /> } B</td></tr>
        <tr><td>Update vote fee</td><td>{ stats && stats.updateVoteFee !== "undefined" && <EgodcoinUtil.CurrencyFormat value={stats.updateVoteFee} currency={stats.currency} /> }</td></tr>
        </tbody>
        <thead><tr><th colSpan="2">Network stats</th></tr></thead>
        <tbody className="w3-small">
        <tr><td>Epoch length</td><td>{ stats && stats.epochLength} slots (slot = {stats && stats.slotDuration} s)</td></tr>
        <tr><td>Max. TX / Block size</td><td>{ stats && <EgodcoinUtil.SizeFormat value={stats.txSizeMax} />} B / {stats && <EgodcoinUtil.SizeFormat value={stats.blockSizeMax} />} B</td></tr>
        <tr><td>Pools / Target</td><td>{stats && stats.poolCount} / {stats && stats.poolCountMax}</td></tr>
        </tbody>
        </table>
        <br/>
        </section>
        <section className="w3-half">
        <h2>{networkLabel} Resources</h2>
        <table className="w3-table-all w3-medium">
        <thead><tr><th colSpan="2">Configurations</th></tr></thead>
        <tbody>
        <tr><td colSpan="2">
          { network === 'egodcoin-testnet' && 
        <div><p>
          <a className="button" href="egodcoin-testnet/configuration.yaml">Node configuration (yaml)</a><br/>
          <a href="egodcoin-testnet/config.json">Node configuration</a><br/>
          <a href="egodcoin-testnet/byron/genesis.json">Byron Genesis</a><br/>
          <a href="egodcoin-testnet/shelley/genesis.json">Shelley Genesis</a><br/>
          <a href="egodcoin-testnet/shelley/genesis.alonzo.json">Shelley Genesis Alonzo</a><br/>
          <a href="egodcoin-testnet/topology.json">Topology</a><br/>
          <a href="egodcoin-testnet/db-sync-config.json">DB-sync node configuration</a>
          <br/><a className="button" href="egodcoin-testnet/egodcoin.org-testnet420.tar.gz">egodcoin.org Testnet</a>&nbsp;&nbsp;
          <a className="button" href="egodcoin-testnet/egodcoin-org-testnet-420.tar.xz.sha256">SHA-256</a><small>&nbsp;* Contains additional scripts or configs to easily setup your relay node or stake pool on linux as described in our tutorial.</small>
          </p></div>
        }
        { network === 'cardano-mainnet' && 
          <div><p>
          <a className="button" href="cardano-mainnet/configuration.yaml" target="_blank" rel="noreferrer">Node configuration (yaml)</a><br/>
          <a href="https://hydra.iohk.io/build/7654130/download/1/mainnet-config.json" target="_blank" rel="noreferrer">Node configuration</a><br/>
          <a href="https://hydra.iohk.io/build/7654130/download/1/mainnet-byron-genesis.json" target="_blank" rel="noreferrer">Byron Genesis</a><br/>
          <a href="https://hydra.iohk.io/build/7654130/download/1/mainnet-shelley-genesis.json" target="_blank" rel="noreferrer">Shelley Genesis</a><br/>
          <a href="https://hydra.iohk.io/build/7654130/download/1/mainnet-alonzo-genesis.json" target="_blank" rel="noreferrer">Shelley Genesis Alonzo</a><br/>
          <a href="https://hydra.iohk.io/build/7654130/download/1/mainnet-topology.json" target="_blank" rel="noreferrer">Topology</a><br/>
          <a href="https://hydra.iohk.io/build/7654130/download/1/mainnet-db-sync-config.json" target="_blank" rel="noreferrer">DB-sync node configuration</a>
          </p></div>
        }
      </td></tr>
        </tbody>
        <thead><tr><th colSpan="2">External Links</th></tr></thead>
        <tbody>
        <tr><td colSpan="2">
          <p><a href="https://github.com/input-output-hk/cardano-node/releases/tag/1.29.0" target="_blank" rel="noreferrer">cardano-node-1.30.0</a><br/>
          <a href={apiDocsUri} target="_blank" rel="noreferrer">Egodcoin API</a><br/>
        <a href="https://www.coincashew.com/coins/overview-ada/guide-how-to-build-a-haskell-stakepool-node" target="_blank" rel="noreferrer">CoinCashew tutorial to setup a stake pool</a><br/></p>
      </td></tr>
        </tbody>
        </table>
        <br/>
          </section>
        <section className="w3-container">
          <h2>{networkLabel} Epochs</h2>
          <EgodcoinEpochs data={epochs} network={network} currency={currency} />
          <br/>
        </section>
        <section className="w3-container">
          <h2>{networkLabel} Blocks</h2>
          <EgodcoinBlocks data={blocks} network={network} currency={currency} />
          <br/>
        </section>
        <section className="w3-container">
          <h2>{networkLabel} Active Stake Pools</h2>
          <EgodcoinExplorer.ActiveStakePoolsTable data={pools} network={network} currency={currency} />
          <br/>
        </section>
      </div>
      )
  }
}
  
export default withRouter(Network);
