import React, { useState } from "react"
import { FiMenu, FiX } from 'react-icons/fi'
import { } from "react-dom"
import { Route, Link, HashRouter } from "react-router-dom"
import { useHistory } from "react-router-dom";

import Home from "./Home"
import Blockchain from "./Blockchain"
import Network from "./Network"
import Block from "./Block"
import Tx from "./Tx"
import Team from "./Team"
import Contact from "./Contact"

function Main() {
    const [open, setOpen] = useState(false)
    return (
		<HashRouter>
          <nav className="navbar">
          <div onClick={() => setOpen(!open)} className="nav-icon">
			  {open ? <FiX /> : <FiMenu />}
		  </div>
            <ul className={open ? 'nav-links active' : 'nav-links'}>
		    <li className="nav-item"><Link onClick={() => setOpen(false)} to="/" className="nav-link">Home</Link></li>
		    <li className="nav-item"><Link onClick={() => setOpen(false)} to="/blockchain" className="nav-link">Blockchain</Link></li>
		    <li className="nav-item"><Link onClick={() => setOpen(false)} to="/network/egodcoin-testnet" className="nav-link">Network</Link></li>
		    <li className="nav-item"><a onClick={() => setOpen(false)} href="https://egodcoin.org/api-docs" className="nav-link">API</a></li>
		    <li className="nav-item"><Link onClick={() => setOpen(false)} to="/team" className="nav-link">Team</Link></li>
		    <li className="nav-item"><Link onClick={() => setOpen(false)} to="/contact" className="nav-link">Contact</Link></li>
		    </ul>
		  </nav>
		  <div className="w3-content w3-auto">
			<Route exact path="/" component={Home}/>
			<Route path="/blockchain" component={Blockchain}/>
			<Route path="/network/:network" component={Network} history={useHistory()}/>
			<Route path="/:network/block/:id" component={Block}/>
			<Route path="/:network/tx/:hash" component={Tx}/>
			<Route path="/team" component={Team}/>
			<Route path="/contact" component={Contact}/>
		  </div>
        </HashRouter>
    )
}

export default Main
