import React from "react"
import EgodcoinUtil from "./EgodcoinUtil"

/**
 * Egodcoin Explorer epochs table for cardano.
 * 
 * @author Andres Jakobs <andresjakobs@web.de>
 * @copyright Andres Jakobs <andresjakobs@web.de>
 */
class EgodcoinEpochs extends React.Component {

  constructor(props) {
    super(props);
    this.state = {}
  }

  componentDidMount() {
    this.updateEpochs()
    this.timerID = setInterval(() => this.updateEpochs(), 5000);
  }

  componentWillUnmount() {
    clearInterval(this.timerID);
  }

  updateEpochs() {
    fetch(global.gConfig.api_base_uri + '/' + this.props.network + '/chain/latestEpochs').then(response => { return response.text() })
      .then(data => {
        var obj = JSON.parse(data)
        console.log("Epochs: " + JSON.stringify(obj))
        this.setState({ data: obj })
      });
  }

  createTable = () => {
    let table = []

    if (this.state && this.state.data && Array.isArray(this.state.data)) {
      for (let i = 0; i < this.state.data.length; i++) {
        let children = []
        children.push(<td>{`${this.state.data[i].no.toLocaleString()}`}</td>)
        children.push(<td>{`${this.state.data[i].slot_count.toLocaleString()}`}</td>)
        children.push(<td>{`${this.state.data[i].blk_count.toLocaleString()}`}</td>)
        children.push(<td>{`${new Date(this.state.data[i].start_time).toLocaleString()}`}</td>)
        children.push(<td>{`${new Date(this.state.data[i].end_time).toLocaleString()}`}</td>)
        children.push(<td>{`${this.state.data[i].tx_count.toLocaleString()}`}</td>)
        children.push(<td><EgodcoinUtil.CurrencyFormat value={`${this.state.data[i].fees}`} currency={this.props.currency} /></td>)
        children.push(<td><EgodcoinUtil.CurrencyFormat value={`${this.state.data[i].out_sum}`} currency={this.props.currency} /></td>)
        table.push(<tr key={`${this.state.data[i].id}`}>{children}</tr>)
      }
    }
    return table
  }

  render() {
    return (
      <table id="epochs-table" className="w3-table-all w3-hoverable">
        <thead><tr><th>Epoche</th><th>Slots</th><th>Blocks</th><th>Started</th><th>Last Block</th><th>Tx</th><th>Fees</th><th>Output</th></tr></thead>
        <tbody>
          {this.createTable()}
        </tbody>
      </table>
    );
  }
}

export default EgodcoinEpochs

