import NumberFormat from 'react-number-format'

/**
 * Utility class.
 * 
 * @author Andres Jakobs <andresjakobs@web.de>
 * @copyright Andres Jakobs <andresjakobs@web.de>
 */
class EgodcoinUtil {

  static toNetworkLabel(network) {
    switch (network) {
      case 'cardano-mainnet': {
        return 'Cardano Mainnet'
      }
      default: {
        return 'Egodcoin Testnet'
      }
    }
  }

  static toCurrency(network) {
    switch (network) {
      case 'cardano-mainnet': {
        return '₳'
      }
      default: {
        return '$E'
      }
    }
  }

  static CurrencyFormat(props) {
    return (
      <NumberFormat
        thousandsGroupStyle="thousand"
        value={props.value / 1000000}
        prefix={props.currency + " "}
        decimalSeparator="."
        decimalScale="6"
        fixedDecimalScale={true}
        displayType="text"
        type="text"
        thousandSeparator={true}
        allowNegative={false}
        defaultValue="-" />
    )
  }

  static SizeFormat(props) {
    return (
      <NumberFormat
        thousandsGroupStyle="thousand"
        thousandSeparator={true}
        value={props.value}
        displayType="text"
        type="text"
        defaultValue="-" />
    )
  }

  static toHexString(byteArray) {
    return byteArray.reduce((output, elem) => (output + ('0' + elem.toString(16)).slice(-2)), '')
  }
}
export default EgodcoinUtil;
