import React from "react"
import { withRouter } from "react-router"
import EgodcoinUtil from "./util/EgodcoinUtil"
import EgodcoinExplorer from "./util/EgodcoinExplorer"

class Tx extends React.Component {

  constructor({ match }, props) {
    super(props);
    const apiUri = global.gConfig.api_base_uri + '/' + match.params.network
    this.state = {
      apiUri: apiUri,
      network: match.params.network,
      networkLabel: EgodcoinUtil.toNetworkLabel(match.params.network),
      currency: EgodcoinUtil.toCurrency(match.params.network),
      tx: null,
      hash: null,
      txout: null,
      txin: null
    }
    fetch(apiUri + '/chain/tx/' + match.params.hash)
      .then(response => { return response.text() })
      .then(data => {
        console.debug('Fetch TX ' + match.params.hash + " - " + JSON.stringify(data))
        var obj = JSON.parse(data)
        this.state.tx = obj[0]
        this.state.hash = EgodcoinUtil.toHexString(obj[0].hash.data)
        this.setState(this.state);
      })
    fetch(apiUri + '/chain/txout/' + match.params.hash)
      .then(response => { return response.text() })
      .then(data => {
        console.debug('Fetch txout ' + JSON.stringify(data))
        this.state.txout = JSON.parse(data)
        this.setState(this.state)
      })
    fetch(apiUri + '/chain/txin/' + match.params.hash)
      .then(response => { return response.text() })
      .then(data => {
        console.debug('Fetch txin ' + JSON.stringify(data))
        this.state.txin = JSON.parse(data)
        this.setState(this.state)
      })
  }

  render() {
    if (this.state.tx) {
      if (this.state.txout && this.state.txin) {
        return (
          <div className="w3-center w3-row-padding">
            <h2>{this.state.networkLabel} Transaction</h2>
            <EgodcoinExplorer.TxDetails tx={this.state.tx} network={this.state.network} networkLabel={this.state.networkLabel} currency={this.state.currency} />
            <br />
            <EgodcoinExplorer.Txout tx={this.state.tx} txout={this.state.txout} network={this.state.network} networkLabel={this.state.networkLabel} currency={this.state.currency} />
            <br />
            <EgodcoinExplorer.Txin tx={this.state.tx} txin={this.state.txin} network={this.state.network} networkLabel={this.state.networkLabel} currency={this.state.currency} />
            <br />
          </div>)
      } else {
        return (
          <div className="w3-center w3-row-padding">
            <h2>{this.state.networkLabel} Transaction</h2>
            <EgodcoinExplorer.TxDetails tx={this.state.tx} network={this.state.network} networkLabel={this.state.networkLabel} currency={this.state.currency} />
            <br />
          </div>)
      }
    } else {
      return (
        <div className="w3-center w3-row-padding">
          <h2>Object not found</h2>
        </div>)
    }
  }
}

export default withRouter(Tx)
