import React from "react"
import { Link } from "react-router-dom"
import EgodcoinUtil from "./EgodcoinUtil"

/**
 * Egodcoin Explorer blocks table for cardano.
 * 
 * @author Andres Jakobs <andresjakobs@web.de>
 * @copyright Andres Jakobs <andresjakobs@web.de>
 */
class EgodcoinBlocks extends React.Component {

  constructor(props) {
    super(props)
    this.state = {}
  }

  componentDidMount() {
    this.updateBlocks()
    this.timerID = setInterval(() => this.updateBlocks(), 5000);
  }

  componentWillUnmount() {
    clearInterval(this.timerID);
  }

  updateBlocks() {
    fetch(global.gConfig.api_base_uri + '/' + this.props.network + '/chain/latestBlocks').then(response => { return response.text() })
      .then(data => {
        var obj = JSON.parse(data)
        console.log("Blocks: " + JSON.stringify(obj))
        this.setState({ data: obj })
      });
  }

  createTable = () => {
    let table = []
    if (Array.isArray(this.state.data)) {
      for (let i = 0; i < this.state.data.length; i++) {
        let children = []
        children.push(<td>{`${this.state.data[i].epoch_no.toLocaleString()}`}</td>)
        children.push(<td>{`${(this.state.data[i].slot_no % 43200).toLocaleString()}`}</td>)
        children.push(<td>{`${this.state.data[i].block_no.toLocaleString()}`}</td>)
        children.push(<td className='hash'><Link to={`/${this.props.network}/block/${this.state.data[i].id}`} className="nav-link">{`${EgodcoinUtil.toHexString(this.state.data[i].hash.data)}`}</Link></td>)
        children.push(<td>{`${new Date(this.state.data[i].time).toLocaleString()}`}</td>)
        children.push(<td>{`${this.state.data[i].tx_count.toLocaleString()}`}</td>)
        children.push(<td>{`${this.state.data[i].size.toLocaleString()}`}</td>)
        children.push(<td>{`${this.state.data[i].slot_leader_id} (${this.state.data[i].vrf_key.substring(7, 13)})`}</td>)
        table.push(<tr key={`${this.state.data[i].id}`}>{children}</tr>)
      }
    }
    return table
  }

  render() {
    return (
      <table id="blocks-table" className="w3-table-all w3-hoverable">
        <thead><tr><th>Epoch</th><th>Slot</th><th>Block</th><th>Hash</th><th>Started</th><th>TX</th><th>Size (b)</th><th>Slot-Leader</th></tr></thead>
        <tbody>
          {this.createTable()}
        </tbody>
      </table>
    );
  }
}

export default EgodcoinBlocks

