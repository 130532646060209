import React, { Component } from "react";
 
class Team extends Component {
  render() {
    return (
	  <div>
		  <div className="w3-center w3-row-padding">
		  <h2>Team¹</h2>
		  <section className="w3-quarter">
		  <h3>Engineering I</h3>
		  <p>Deals with science and technology.<br/>Has started this blockchain.<br/>mailto: buy [at] egodcoin.cash</p>
		  </section>
		  <section className="w3-quarter">
		  <h3>Glash</h3>
		  <p>Cardano compatible digital blockchain agent.<br/>Currently under development.<br/>mailto: glash [at] glash.org</p>
		  </section>
		  <section className="w3-quarter">
		  </section>
		  <section className="w3-quarter">
		  </section>
		  </div>
		  <div className="w3-west">
		  <p><small>¹Team list is dynamic. Egodcoin is PoS!</small></p>
		  </div>
      </div>
    );
  }
}
 
export default Team;
