import React, { useState, useEffect } from "react"
import { withRouter } from "react-router"
import EgodcoinUtil from "./util/EgodcoinUtil"
import EgodcoinExplorer from "./util/EgodcoinExplorer"

function Block({match}) {
  // eslint-disable-next-line
  const [network, setNetwork] = useState(match.params.network)
  const [networkLabel, setNetworkLabel] = useState(false)
  const [currency, setCurrency] = useState(false)
  const id = match.params.id
  const [block, setBlock] = useState(false)
  const [tx, setTx] = useState(false)
  useEffect(() => {
	const apiUri = global.gConfig.api_base_uri + '/' + match.params.network
	setNetwork(match.params.network)
	setNetworkLabel(EgodcoinUtil.toNetworkLabel(match.params.network))
	setCurrency(EgodcoinUtil.toCurrency(match.params.network))
    fetch(apiUri + '/chain/block/' + id)
	  .then(response => { return response.text() })
	  .then(data => {
		console.debug('Fetch block ' + JSON.stringify(data))
		var obj = JSON.parse(data)
		setBlock(obj[0])
	  })
    fetch(apiUri + '/chain/block/' + id + '/tx')
	  .then(response => { return response.text() })
	  .then(data => {
		console.debug('Fetch block TX ' + JSON.stringify(data))
		setTx(JSON.parse(data))
	  })
  }, [id, match.params.network])
  if (block) {
    if (block.tx_count < 1) {
      return (
        <div className="w3-center w3-row-padding">
          <EgodcoinExplorer.BlockDetails block={block} />
          <br/>
          <EgodcoinExplorer.BlockTx block={block} tx={tx} network={network} networkLabel={networkLabel} currency={currency}/>
          <p>0 transactions.</p>
          <br/>
        </div>
      )
    } else {
		return (
        <div className="w3-center w3-row-padding">
          <EgodcoinExplorer.BlockDetails block={block} networkLabel={networkLabel} />
          <br/>
          <EgodcoinExplorer.BlockTx block={block} tx={tx} network={network} networkLabel={networkLabel} currency={currency}/>
          <br/>
        </div>
      )
	}
  } else {
    return (
      <div className="w3-center w3-row-padding">
        <h2>Object not found</h2>
      </div>
    )
  }
}

export default withRouter(Block)
